input[type="text"],
input[type="password"],
input[type="email"],
textarea,
select {
   display: block;
   padding: 18px 15px;
   margin: 0 0 24px 0;

   min-width: 225px;
   max-width: 100%;
   font-size: 15px;
   line-height: 24px;
   color: #647373;
   background: #D3D9D9;

}

.Input {
    width:100%;
    padding: 10px;
    box-sizing: border-box;
}

.Label{
    font-weight: bold;
    display: block;
    margin-bottom: 8px;

}

.InputElement{
    outline: none;
    border: 1px solid #ccc;
    background-color: white;
    padding: 6px 10px;
    display: block;
    width: 100%;
    font: inherit;
    box-sizing: border-box;
}

.InputElement:focus {
    outline: none;
    /* background-color: #ccc; */
}

.Invalid {
    border: 2px solid red;
    background-color: rgb(224, 180, 175);

}
.ValidationError {
    color: red;
    margin: 5px 0;
} 
