.Album{
    width: 200px;
    height: 200px;
    margin:0px 5px;
}
.Album {
    background-color:rgba(0, 0, 0, 0.1);
    z-index: 0;
}
.Album img:hover{
  opacity: 0.3;
  cursor: pointer;
}
.hide{
    display: none;
}
.show{
    position: relative;
    top: -30%;
    color:white;
    text-shadow: rgba(0,0,0,0,1);
    pointer-events: none;
    font-weight: bolder;

}
/* .Album img:global(.opaque) {
        opacity:1;
        -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        filter: alpha(opacity=1);
        pointer-events: auto;
    
    } */

.lightbox {
    /** Default lightbox to hidden */
    /*display: block;*/
    opacity: 1;
    /** Position and style */
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    text-align: center;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.88);
    visibility: visible;
    /*transition: visibility .3s, opacity .3s linear;*/
    transition: all .5s ;
}

.lightbox img {
    /** Pad the lightbox image */
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    /*padding: 10px;*/
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    opacity:0;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    pointer-events:none;

}

.lightbox img:global(.opaque) {
    opacity:1;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=1);
    pointer-events: auto;

}