.Guard{
    background-color: white;
    text-align: left;
    padding-left:5px;
    width:100%;
    height:100%;
}

.Guard input{
    height:5px;
    
}

.Guard button{
    background-color: red;
    height:30px;
    padding: 20px;
    font: 16px;
    line-height: 2px;
    margin-bottom: 10px;
}