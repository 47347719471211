.Modal {
    position: fixed;
    z-index: 1200;
    background-color: white;
    width: 70%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 15%;
    top: 10%;
    box-sizing: border-box;
    transition: all 1.3s ease-out;

}



/* @media (min-width: 600px) {

}
@media (min-width: 1000px) and (min-height:900px){

} */

@media only screen and (min-width: 768px) {
    /* tablets and desktop */
    .Modal {
        max-width: 1500px;
        /* width: auto; */
        max-height: 500px;
        /* height: auto; */
        /* left: calc(50% - 750px); */
        left: 20%;
        right: 20%;
        /* left: 25%; */
        /* top: 25%; */
        /* bottom: 25%; */
        display: inline-table;
        position: fixed;
        top: 15%;
    }     
}

@media only screen and (max-width: 767px) {
    /* phones */
    .Modal {
        width: 300px;
        left:5%;
        right:5%;
    }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
    /* portrait phones */
    .Modal {
        width: 300px;
        left:5%;
        right:5%;
    }
}