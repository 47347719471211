.Button {
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 10px;
    margin: 10px;
    font-weight: bold;
}

.Button:first-of-type {
    margin-left: 0;
    padding-left: 0;
}

.Button:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.Success {
    color: #5C9210;
}
.Success:hover{
    color: #5C9210;
    background-color: white;
}
.Danger {
    color: #944317;
    
}
.Danger:hover {
    color: #944317;
    background-color: white;
}

.Info {
    color:#1985e9;
}
.Info:hover{
    color:#1985e9;
    background-color: white;
}